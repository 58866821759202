import Vue from 'vue';
import App from './App.vue';

import '@/components';

const isProduction = process.env.NODE_ENV === 'production';

// router
import router from '@/routes/index';

// vuex
import store from '@/store/index';

// logger
import VueLogger from 'vuejs-logger';

import vueMoment from 'vue-moment';

const options = {
	isEnabled: isProduction ? false : true,
	logLevel: 'debug',
	stringifyArguments: false,
	showLogLevel: true,
	showMethodName: true,
	separator: '|',
	showConsoleColors: true,
};
Vue.use(vueMoment);

Vue.use(VueLogger, options);

import Dialog from './plugins/dialog';
Vue.use(Dialog, { window });

Vue.config.productionTip = false;
new Vue({
	render: h => h(App),
	router,
	store,
}).$mount('#app');

Vue.prototype.$EventBus = new Vue();

export default [
	{
		path: '/building/memo/regist',
		name: 'memoRegist',
		component: () => import('@/views/memo/memoRegist.vue'),
	},
	{
		path: '/building/memo/update',
		name: 'memoUpdate',
		props: true,
		component: () => import('@/views/memo/memoUpdate.vue'),
	},
	{
		path: '/building/memo/objet/:buildingId/:floorId/:roomId/:objectPinId',
		name: 'memoList',
		component: () => import('@/views/memo/memoList.vue'),
	},
	{
		path: '/building/memo/:id',
		name: 'memoDetail',
		component: () => import('@/views/memo/memoDetail.vue'),
	},
];

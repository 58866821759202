export default [
	{
		path: '/community',
		name: 'communityList',
		props: true,
		component: () => import('@/views/community/communityList.vue'),
	},
	{
		path: '/community/regist',
		name: 'communityRegist',
		props: true,
		component: () => import('@/views/community/communityRegist.vue'),
	},
	{
		path: '/community/update',
		name: 'communityUpdate',
		props: true,
		component: () => import('@/views/community/communityUpdate.vue'),
	},
	{
		path: '/community/:id',
		name: 'communityDetail',
		component: () => import('@/views/community/communityDetail.vue'),
	},
];

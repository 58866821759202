export default [
	{
		path: '/building/room/:id',
		name: 'roomDetail',
		component: () => import('@/views/room/roomDetail.vue'),
		//props: true,
	},

	{
		// path: '/building/roomEdit/:id',
		path: '/building/roomEdit/:id:floorPlanId:imageId',
		name: 'roomEdit',
		component: () => import('@/views/room/roomEdit.vue'),
		//props: true,
	},
];

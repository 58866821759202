/**
 * @NOTICE
 * 공통으로 사용되는 유틸리티 관리하는 디렉토리 입니다.
 */

import store from '@/store';
import Cookies from 'js-cookie';

//spinner
export function showSpinner() {
	setSpinner(true);
}

export function hideSpinner() {
	setSpinner(false);
}

export function setSpinner(value) {
	store.commit('spinner', value);
}

//spinner
export function showMenu() {
	setMenu(true);
}

export function hideMenu() {
	setMenu(false);
}

export function setMenu(value) {
	store.commit('menu', value);
}

export function isAuthorized() {
	let auth = getAuth();
	return !!auth;
}

const authKey = 'iw-auth';
export function setAuth(value) {
	if (value) {
		let params = {};

		if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
			if (value.session)
				params.cookie = `INSITE_JSESSIONID=${value.session}; Path=/; Domain=.insitenote.com; HttpOnly;`;
			if (value.rememberMe) params.rememberMe = value.rememberMe;
			window.ReactNativeWebView.postMessage(JSON.stringify(params));
		}

		value.session && delete value.session;
		value.rememberMe && delete value.rememberMe;

		store.commit('auth', value);
		// localStorage.setItem(authKey, JSON.stringify(value));
		Cookies.set(authKey, JSON.stringify(value), { path: '/', domain: '.insitenote.com' });
		Cookies.set(authKey, JSON.stringify(value), { path: '/', domain: 'localhost' });
		// Cookies.set(authKey, JSON.stringify(value));
	} else {
		store.commit('auth', null);
		// localStorage.removeItem(authKey);
		Cookies.remove(authKey, { path: '/', domain: '.insitenote.com' });
		Cookies.remove(authKey, { path: '/', domain: 'localhost' });
		// Cookies.remove(authKey);
	}
}

export function getAuth() {
	let res = Cookies.get(authKey);

	if (res) {
		try {
			res = JSON.parse(res);
		} catch (error) {
			console.error('Error parsing authentication information:', error);
			res = null;
		}
	}

	//update store with authentication information
	store.commit('auth', res);

	return res;
}

// export function getAuth() {
// 	let res = store.state.auth;
// 	if (!res) {
// 		res = localStorage.getItem(authKey);
// 		res = res && JSON.parse(res);
// 		store.commit('auth', res);
// 	}
// 	return res;
// }

export function isY(val) {
	return 'Y' === val || 'y' === val;
}

export function isN(val) {
	return 'N' === val || 'n' === val;
}

let reEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
export function validEmail(value) {
	return value && reEmail.test(value);
}

let reDate = /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
export function validDate(value) {
	return value && reDate.test(value);
}

export function hasOwnProperty(obj, key) {
	if (!obj) {
		return false;
	}
	return Object.prototype.hasOwnProperty.call(obj, key);
}

export function isUndef(obj) {
	return undefined === obj || null === obj;
}

let reTotal = /\B(?=(\d{3})+(?!\d))/g;
export function numberComma(val) {
	return String(val).replace(reTotal, ',');
}

export function dateFormatYear(val) {
	return val.replaceAll('/', '.').split(' ')[0];
}

export function dateFormatMonth(val) {
	return val.replaceAll('/', '.').split(' ')[1];
}

let imageExt = ['jpg', 'jpeg', 'png', 'bmp'];
export function isImage(file) {
	let ext = file.type
		.split('/')
		.pop()
		.toLowerCase();
	let check = imageExt.find(i => i === ext);
	return check ? true : false;
}

export function phoneFormat(val = '') {
	let formatNum = '';
	if (val.length == 11) {
		formatNum = val.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
	} else if (val.length == 8) {
		formatNum = val.replace(/(\d{4})(\d{4})/, '$1-$2');
	} else {
		if (val.indexOf('02') == 0) {
			formatNum = val.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
		} else {
			formatNum = val.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
		}
	}
	return formatNum;
}

export function overFileSize(fileList, file, totalFileSize) {
	let maxFileSize = 1024 * 1024 * 100; // 100MB
	let flag = false;
	fileList.forEach(f => (totalFileSize += f.size ? f.size : 0));
	totalFileSize = Array.isArray(file) ? file.reduce((a, b) => a + b.size, totalFileSize) : totalFileSize + file.size;
	if (totalFileSize > maxFileSize) flag = true;
	return flag;
}

export function sizeOfImage(src) {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.onload = function() {
			resolve({ width: this.width, height: this.height });
		};
		img.onerror = function() {
			reject({ message: `이미지 로딩 실패: ${this.src}` });
		};
		img.src = src;
	});
}

export function textLengthOverCut(txt, len, lastTxt) {
	if (len == '' || len == null) {
		// 기본값
		len = 20;
	}
	if (lastTxt == '' || lastTxt == null) {
		// 기본값
		lastTxt = '...';
	}
	if (txt.length > len) {
		txt = txt.substr(0, len) + lastTxt;
	}
	return txt;
}

export function getCoordinate(address) {
	return new Promise(function(resolve) {
		let ret = null;
		if (!address) {
			resolve(ret);
		} else {
			const geocoder = new window.kakao.maps.services.Geocoder();
			geocoder.addressSearch(address, function(result, status) {
				if (status === window.kakao.maps.services.Status.OK) {
					ret = { longitude: result[0].x, latitude: result[0].y }; // 위도
				}
				resolve(ret);
			});
		}
	});
}

export function setIntervalStr(intervalType) {
	let intervalStr = '매년';
	switch (intervalType) {
		case 'Y':
			intervalStr = '매년';
			break;
		case 'M':
			intervalStr = '매월';
			break;
		case 'W':
			intervalStr = '매주';
			break;
		case 'D':
			intervalStr = '지정일';
			break;
	}
	return intervalStr;
}

export default [
	{
		path: '/building/floor/:id',
		name: 'floorDetail',
		component: () => import('@/views/floor/floorDetail.vue'),
	},
	{
		// path: '/building/floor/objectpin/:id',
		path: '/building/floor/objectpin/:id:floorPlanId:imageId',
		name: 'floorObjectPin',
		component: () => import('@/views/floor/objectPinEdit.vue'),
	},
];

export default [
	{
		path: '/building',
		name: 'buildingList',
		component: () => import('@/views/building/buildingList.vue'),
	},
	{
		path: '/building/regist',
		name: 'buildingRegist',
		component: () => import('@/views/building/buildingRegist.vue'),
	},
	{
		path: '/building/tip',
		name: 'buildingTip',
		component: () => import('@/views/building/buildingTip.vue'),
	},
	{
		path: '/building/:id',
		name: 'buildingDetail',
		component: () => import('@/views/building/buildingDetail.vue'),
	},
	{
		path: '/building/edit/:id',
		name: 'buildingEdit',
		component: () => import('@/views/building/buildingEdit.vue'),
	},
];

export default [
	{
		path: '/building/project/:buildingId',
		name: 'projectList',
		component: () => import('@/views/project/projectList.vue'),
	},
	{
		path: '/building/project/regist/:buildingId',
		name: 'projectRegist',
		component: () => import('@/views/project/projectRegist.vue'),
	},
	{
		path: '/building/project/update/:buildingId/:id',
		name: 'projectUpdate',
		component: () => import('@/views/project/projectUpdate.vue'),
	},
	{
		path: '/building/project/memoList/:buildingId/:id',
		name: 'projectMemoList',
		component: () => import('@/views/project/projectMemoList.vue'),
	},
];

import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '@/routes/home';
import building from '@/routes/building';
import floor from '@/routes/floor';
import floorPlan from '@/routes/floorPlan';
import user from '@/routes/user';
import community from '@/routes/community';
import memo from '@/routes/memo';
import room from '@/routes/room';
import project from '@/routes/project';
import tenant from '@/routes/tenant';
import error from '@/routes/error';
import { isAuthorized } from '@/utils/cmm';
import { showMenu } from '@/utils/cmm';

Vue.use(VueRouter);

/**
 * @NOTICE
 * router 디렉토리 입니다,
 * 관심사에 따라 파일을 생성후 "routes: []"에 추가 하시면 됩니다.
 * sample1.js, sample2.js를 참고하세요.
 */
const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			props: true,
		},
		{
			path: '/register',
			name: 'register',
			component: () => import('@/views/user/register.vue'),
		},
		{
			path: '/findId',
			name: 'findId',
			component: () => import('@/views/user/findId.vue'),
		},
		{
			path: '/findPassword',
			name: 'findPassword',
			component: () => import('@/views/user/findPassword.vue'),
		},
		{
			path: '/notsupport',
			name: 'notBrowser',
			component: () => import('@/views/errors/NotBrowser.vue'),
		},
		{
			path: '/',
			// name: 'root',
			component: () => import('@/views/layouts/LayoutFrame.vue'),
			children: [
				...home,
				...building,
				...floor,
				...floorPlan,
				...user,
				...community,
				...memo,
				...room,
				...project,
				...tenant,
			],
		},
		...error,
	],
});

router.beforeEach(onBeforeEnter);

async function onBeforeEnter(to, from, next) {
	if (
		'buildingTip' === to.name ||
		'term1' === to.name ||
		'term2' === to.name ||
		'resetPassword' === to.name ||
		'findPassword' === to.name ||
		'findedId' === to.name ||
		'findId' === to.name ||
		'register' === to.name ||
		'snsRegister' === to.name ||
		'notFound' === to.name ||
		'notBrowser' === to.name
	) {
		return next();
	}

	if (isAuthorized()) {
		showMenu();

		if ('/' === to.path || '/mypage' === to.path) {
			return next();
		}

		return next();
	}
	if ('login' === router.currentRoute.name) {
		return;
	}
	'login' === to.name ? next() : next({ name: 'login', params: { from: to.name } });
}

export default router;

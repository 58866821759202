<template>
	<div>
		<router-view></router-view>
		<div class="layerPop on" v-show="isSpinner">
			<div class="spinner"></div>
		</div>
		<div :class="{ 'layerPop alert': true, on: isAlert }">
			<div class="inner">
				<div class="cont">
					<div class="inBox">
						<strong>{{ title }}</strong>
						<p v-html="message"></p>
					</div>
				</div>
				<div class="btnArea">
					<button type="button" class="btn" ref="alertOk" @click="onClose(undefined)">확인</button>
				</div>
			</div>
		</div>
		<div :class="{ 'layerPop alert': true, on: isConfirm }">
			<div class="inner">
				<div class="cont">
					<div class="inBox">
						<strong>{{ title }}</strong>
						<p v-html="message"></p>
					</div>
				</div>
				<div class="btnArea">
					<button type="button" class="btn btnGray" name="layer03" @click="onClose(false)">취소</button>
					<button type="button" class="btn" name="layer03" ref="confirmOk" @click="onClose(true)">확인</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { version } from '../package';

export default {
	data: function() {
		return {
			isConfirm: false,
			isAlert: false,
			title: '',
			message: '',
			dialogResolve: null,
			idx: 0,
			step: 0,
		};
	},
	computed: {
		version() {
			return version;
		},
		isSpinner() {
			return !(this.isConfirm || this.isAlert) && this.$store.state.spinner;
		},
	},
	created() {
		if (window.navigator.userAgent.match(/MSIE|Internet Explorer|Trident/i)) {
			return this.$router.push({ name: 'notBrowser' });
		}
		this.$dialog = this;
		window.Kakao.init(process.env.VUE_APP_KAKAO_ID);
	},
	methods: {
		confirm(message, title) {
			if (this.isConfirm) return Promise.reject();
			if (this.dialogResolve) return Promise.reject();
			let self = this;
			this.title = title || '안내';
			this.message = message || '';
			if ('string' === typeof this.message) {
				this.message = this.message.replace(/\r\n/g, '<br/>');
			}

			this.isConfirm = true;
			setTimeout(() => this.$refs.confirmOk.focus(), 100);
			return new Promise(function(resolve) {
				self.dialogResolve = resolve;
			});
		},
		alert(message, title) {
			if (this.isAlert) return Promise.reject();
			if (this.dialogResolve) return Promise.reject();
			let self = this;
			this.title = title || '알림';
			this.message = message || '';
			if ('string' === typeof this.message) {
				this.message = this.message.replace(/\r\n/g, '<br/>');
			}
			this.isAlert = true;
			setTimeout(() => this.$refs.alertOk.focus(), 100);
			return new Promise(function(resolve) {
				self.dialogResolve = resolve;
			});
		},
		onClose(value) {
			this.isConfirm = false;
			this.isAlert = false;
			if (undefined === value) {
				this.dialogResolve();
			} else {
				this.dialogResolve(value);
			}
			this.dialogResolve = null;
		},
	},
};
</script>

<style scoped>
.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	display: inline-block;
	width: 80px;
	height: 80px;
	margin: -32px 0 0 -32px;
}
.spinner:after {
	content: ' ';
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>

export default [
	{
		path: '/user',
		name: 'user',
		component: () => import('@/views/user/myInfo.vue'),
	},
	{
		path: '/user/manageFriend',
		name: 'manageFriend',
		component: () => import('@/views/user/manageFriend.vue'),
	},
	{
		path: '/user/manageMemo',
		name: 'manageMemo',
		component: () => import('@/views/user/manageMemo.vue'),
	},
	{
		path: '/user/resignUser',
		name: 'resignUser',
		component: () => import('@/views/user/resignUser.vue'),
	},
	{
		path: '/user/companyIntroduction',
		name: 'companyIntroduction',
		component: () => import('@/views/user/companyIntroduction.vue'),
	},
	{
		path: '/user/term1',
		name: 'term1',
		component: () => import('@/views/user/term1.vue'),
	},
	{
		path: '/user/term2',
		name: 'term2',
		component: () => import('@/views/user/term2.vue'),
	},
];
